import React from "react";
import { StaticQuery, graphql } from "gatsby";


const MarkRender = () => (
  <StaticQuery  
    query={FILE_QUERY}
    render={({ allMarkdownRemark }) =>
      allMarkdownRemark.edges.map(({ node }) => (
        <div key={node.id}
          dangerouslySetInnerHTML={{ __html: node.html }}
        />
        ))
      }
  />
);

const FILE_QUERY = graphql`
  query SingleFile {
    allMarkdownRemark(
      filter: { frontmatter: { slug: {eq: "press-col"} }}
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            slug
          }
        }
      }
    }
  }
`;

export default MarkRender;