import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Layout from "../components/layout";
import factData from "../presskit/data/factsheet";
import PostSEO from '../components/post-seo';
import MarkRender from "../components/file-source";

import screen1 from "../images/screenshots/Dive03.jpg";


const imageObj = {
  src: screen1,
  width: "300",
  height: "200",
};

const FactListDiv = props => {
  const factList = props.data;
  return factList.map((item, i) => (
    <div className="fact" key={i}>
      <span className="head1">{item.title}:</span>
      {item.description}
    </div>
  ));
};

const PlainListDiv = props => {
  const factList = props.data;
  return factList.map((item, i) => (
    <div className="fact" key={i}>
      <div className="head1">{item.title}</div>
      {item.description}
    </div>
  ));
};

const LinkDiv = props => {
  const links = props.data;
  return links.map((item, i) => (
    <div className="fact" key={i}>
      <a href={item.url}>{item.title}</a>
    </div>
  ));
};

const LinkMailDiv = props => {
  const links = props.data;
  return links.map((item, i) => (
    <div className="fact" key={i}>
      <div className="head1">{item.title}</div>
      <a href={item.url}>{item.url}</a>
    </div>
  ));
};

const TwoCols = ({ credits, contacts }) => (
  <Row>
    <Col md={7}>
      <h3>Dune Sea Credits</h3>
      <PlainListDiv data={credits} />
    </Col>
    <Col>
      <h3>Contact</h3>
      <LinkMailDiv data={contacts} />
    </Col>
  </Row>
);

const PressPage = () => {
  return (
    <Layout logo={true}>
      <PostSEO title="Frolic Labs" description="Frolic Labs Press Kit" image={imageObj} />
      <BodyStyle>
        <Container>
          <Row className="justify-content-md-center row-padtop">
            <Col md={3}>
              <h3>Fact Sheet</h3>
              <FactListDiv data={factData.factList} />
              <div className="fact head1">Social Media</div>
              <LinkDiv data={factData.socialLinks} />
            </Col>
            <Col md={9}>
              <div className="desc">
                <MarkRender />
              </div>

              <TwoCols
                credits={factData.credits}
                contacts={factData.contacts}
              />
            </Col>
          </Row>
        </Container>
      </BodyStyle>
    </Layout>
  );
};

const BodyStyle = styled.div`
 
  h3 {
    color: #7c0b47;
    font-family: "Roboto", sans-serif;
    font-weight: 800;
    margin-bottom: 0.8em;
  }

  h4 {
    color: #7c0b47;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    margin-bottom: 0.5em;
  }

  .desc {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1.1em;
  }

  .head0 {
    color: #7c0b47;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
  }

  .fact {
    font-size: 1.1em;
    margin-bottom: 0.9em;
  }
  .head1 {
    font-weight: 700;
    padding-right: 8px;
  }
  .nobold {
    font-weight: 400;
  }
`;

export default PressPage;
